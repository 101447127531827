/* eslint-disable */
// @ts-nocheck

'use client'
import Script from 'next/script'
import React, { ReactElement, useContext } from 'react'
import { ConfigContext } from '@/components/common/ConfigContext'
import { Language } from '@/types/Page'
import { useTranslations } from 'next-intl'

function translatedConfig(translate: (key: string) => string): any {
  return {
    consent_modal: {
      title: translate('Main.CookieConsent.ConsentModal.title'),
      description: translate('Main.CookieConsent.ConsentModal.description'),
      primary_btn: {
        text: translate('Main.CookieConsent.ConsentModal.primaryButtonText'),
        role: 'accept_all', //"accept_selected" or "accept_all"
      },
      secondary_btn: {
        text: translate('Main.CookieConsent.ConsentModal.secondaryButtonText'),
        role: 'settings', //"settings" or "accept_necessary"
      },
    },
    settings_modal: {
      title: translate('Main.CookieConsent.SettingsModal.title'),
      save_settings_btn: translate('Main.CookieConsent.SettingsModal.saveSettingsButton'),
      accept_all_btn: translate('Main.CookieConsent.SettingsModal.acceptAllButton'),
      close_btn_label: '',
      blocks: [
        {
          title: translate('Main.CookieConsent.ConsentModal.title'),
          description: translate('Main.CookieConsent.SettingsModal.settingsDescription'),
        },
        {
          title: translate('Main.CookieConsent.SettingsModal.requiredSettingsTitle'),
          description: translate('Main.CookieConsent.SettingsModal.requiredSettingsDescription'),
          toggle: {
            value: 'functional_storage',
            enabled: true,
            readonly: true,
          },
        },
        {
          title: translate('Main.CookieConsent.SettingsModal.analyticsSettingsTitle'),
          description: translate('Main.CookieConsent.SettingsModal.analyticsSettingsDescription'),
          toggle: {
            value: 'analytics_storage',
            enabled: true,
            readonly: false,
          },
        },
        {
          title: translate('Main.CookieConsent.SettingsModal.advertisingSettingsTitle'),
          description: translate('Main.CookieConsent.SettingsModal.advertisingSettingsDescription'),
          toggle: {
            value: 'ad_storage',
            enabled: true,
            readonly: false,
          },
        },
      ],
    },
  }
}

function translations(translate: (key: string) => string): Record<Language, any> {
  return Object.fromEntries(
    Object.values(Language).map((language) => [language, translatedConfig(translate)]),
  ) as Record<Language, any>
}

function initCC(language: Language, translate: (key: string) => string): void {
  window.dataLayer = window.dataLayer || []

  function gtag(): void {
    window.dataLayer.push(arguments)
  }

  gtag('consent', 'default', {
    /* Google TagManager requires those */
    functional_storage: 'granted',
    analytics_storage: 'granted',
    ad_storage: 'granted',
    ad_personalization: 'granted',
    ad_user_data: 'granted',

    /* These are the actual categories */
    custom_functional_storage: 'granted',
    custom_ad_storage: 'denied',
    custom_analytics_storage: 'denied',
  })

  if (typeof initCookieConsent === 'function') {
    const cookieconsent = initCookieConsent()
    cookieconsent.run({
      current_lang: language,
      cookie_domain: window.location.hostname,
      /*theme_css : "./cookieconsent.css",*/

      onAccept: function () {
        if (cookieconsent.allowedCategory('analytics_storage')) {
          gtag('consent', 'update', { custom_analytics_storage: 'granted' })
        }

        if (cookieconsent.allowedCategory('ad_storage')) {
          gtag('consent', 'update', { custom_ad_storage: 'granted' })
        }

        gtag('event', 'cookieConsentOnAccept')
      },
      gui_options: {
        consent_modal: {
          layout: 'box', // box/cloud/bar
          position: 'bottom', // bottom/top + left/right/center
          transition: 'slide', // zoom/slide
        },
        settings_modal: {
          layout: 'box', // box/bar
          // position : "left",           // left/right
          transition: 'zoom', // zoom/slide
        },
      },
      languages: translations(translate),
    })
  }
}

export default function CookieConsent(): ReactElement {
  const config = useContext(ConfigContext)!
  const translate = useTranslations()

  return (
    <Script defer src="/cookieconsent-min.js" onLoad={() => initCC(config.language, translate)} />
  )
}
