'use client'

import React, { ReactElement } from 'react'
import Script from 'next/script'
import { GTAG_ID } from '@/lib/sharedConstants'
import {
  getABTestAnalyticsUserProperties,
  getTestVariantsCookieValue,
  TEST_VARIANTS_COOKIE,
} from '@/lib/ab-tests/test-variants'
import Cookies from 'js-cookie'

export function TagManager(): ReactElement {
  const testVariantsCookieValue = getTestVariantsCookieValue(Cookies.get(TEST_VARIANTS_COOKIE))
  const abTestUserPropertiesString = JSON.stringify(
    getABTestAnalyticsUserProperties(testVariantsCookieValue),
  )

  if (typeof window === 'undefined') {
    return <></>
  }

  if (
    window.location.hostname.endsWith('fit-reisen.vercel.app') ||
    window.location.hostname === 'bs-local.com' ||
    window.location.hostname === 'localhost'
  ) {
    return <></>
  }

  return (
    <>
      <Script id="setUserProperties">
        {`window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'setUserProperties',
          'user_properties': ${abTestUserPropertiesString},
        })
      `}
      </Script>
      <Script
        id="loadTagManager"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `(function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
            });
              var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${GTAG_ID}');`,
        }}
      />
    </>
  )
}
