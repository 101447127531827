import(/* webpackMode: "eager", webpackExports: ["CurrencySelect"] */ "/vercel/path0/site/components/common/footer/CurrencySelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/site/components/common/header/HeaderContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderWizardWrapper"] */ "/vercel/path0/site/components/common/header/HeaderWizardWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderWrapper"] */ "/vercel/path0/site/components/common/header/HeaderWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LikedHotelsIcon"] */ "/vercel/path0/site/components/common/header/liked_hotels_icon/LikedHotelsIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavigation"] */ "/vercel/path0/site/components/common/header/MobileNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavToggleButton"] */ "/vercel/path0/site/components/common/header/MobileNavToggleButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PhoneNumber"] */ "/vercel/path0/site/components/common/header/PhoneNumber.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientSideEffects"] */ "/vercel/path0/site/components/common/layout/ClientSideEffects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/site/components/common/layout/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TagManager"] */ "/vercel/path0/site/components/common/layout/TagManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/site/components/pages/search-page/SearchPageContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/icons/font-awesome/sharp-light/coins.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/icons/font-awesome/sharp-light/comments.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/icons/font-awesome/sharp-light/thumbs-up.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/icons/font-awesome/sharp-light/user.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/icons/header-mail.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/icons/header-user.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/site/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/react-loading-skeleton/dist/skeleton.css");
