'use client'

import { ReactElement, useEffect } from 'react'
import _invert from 'lodash/invert'
import { onTouchDevice } from '@/lib/utils/utils'
import Bowser from 'bowser'
import Parser = Bowser.Parser.Parser

function isIpad(): boolean {
  return !!(
    /Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1
  )
}

export function ClientSideEffects(props: { skipHeaderAndFooter: boolean }): ReactElement {
  function getBrowserVersion(browser: Parser): string {
    return browser.getBrowserVersion() ? browser.getBrowserVersion().split('.')[0] : ''
  }

  function getBrowserName(browser: Bowser.Parser.Parser): string {
    return _invert(Bowser.BROWSER_MAP)[browser.getBrowserName()]
  }

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent)
    window.document.documentElement.classList.add(getBrowserName(browser))
    window.document.documentElement.classList.add(
      getBrowserName(browser) + getBrowserVersion(browser),
    )
    const platformType = browser.getPlatform().type

    if (platformType) {
      window.document.documentElement.classList.add(platformType)
    }

    if (isIpad()) {
      window.document.documentElement.classList.add('ipad')
    }

    if (window.navigator.userAgent.match(/iPhone;/) || window.navigator.userAgent.match(/iPad;/)) {
      window.document.documentElement.classList.add('ios')
    }

    if (onTouchDevice()) {
      window.document.documentElement.classList.add('touch')
    }
    if (props.skipHeaderAndFooter) {
      window.document.documentElement.classList.add('skipHeaderAndFooter')
    }
  }, [])

  return <></>
}
