'use client'

import { createContext, ReactElement, useEffect, useState } from 'react'
import { FilterModalType, MAP_DISPLAY_STATUS_CHANGED } from '@/lib/sharedConstants'

interface SearchPageContextProps {
  displayMap: boolean
  setDisplayMap: (b: boolean) => void
  setSearchOptionsType: (type: FilterModalType | undefined) => void
  searchOptionsType: FilterModalType | undefined
  storeInitialized: boolean
}

export const SearchPageContext = createContext<SearchPageContextProps>({} as SearchPageContextProps)

export default function SearchPageContextProvider({ children }): ReactElement {
  const [displayMap, setDisplayMap] = useState(false)
  const [searchOptionsType, setSearchOptionsType] = useState<FilterModalType | undefined>()
  const [storeInitialized, setStoreInitialized] = useState(false)

  useEffect(waitForLoadedWizardStore, [])

  function waitForLoadedWizardStore(): void {
    if (!window.store) {
      setTimeout(waitForLoadedWizardStore, 100)
    } else {
      setStoreInitialized(true)
    }
  }

  function setDisplayMapAndPublishEvent(display: boolean): void {
    setDisplayMap(display)
    window.dispatchEvent(new CustomEvent(MAP_DISPLAY_STATUS_CHANGED, {}))
  }

  return (
    <SearchPageContext.Provider
      value={{
        setDisplayMap: setDisplayMapAndPublishEvent,
        displayMap,
        searchOptionsType,
        setSearchOptionsType,
        storeInitialized,
      }}
    >
      {children}
    </SearchPageContext.Provider>
  )
}
